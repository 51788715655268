import axios from "axios";
import { toast } from "react-toastify";

export const MAGAZINE_LIST_REQUEST = "MAGAZINE_LIST_REQUEST";
export const MAGAZINE_LIST_SUCCESS = "MAGAZINE_LIST_SUCCESS";
export const MAGAZINE_LIST_FAILURE = "MAGAZINE_LIST_FAILURE";

export const CREATE_MAGAZINE_REQUEST = "CREATE_MAGAZINE_REQUEST";
export const CREATE_MAGAZINE_SUCCESS = "CREATE_MAGAZINE_SUCCESS";
export const CREATE_MAGAZINE_FAILURE = "CREATE_MAGAZINE_FAILURE";

export const DELETE_MAGAZINE_REQUEST = "DELETE_MAGAZINE_REQUEST";
export const DELETE_MAGAZINE_SUCCESS = "DELETE_MAGAZINE_SUCCESS";
export const DELETE_MAGAZINE_FAILURE = "DELETE_MAGAZINE_FAILURE";

export const DONATION_LIST_REQUEST = "DONATION_LIST_REQUEST";
export const DONATION_LIST_SUCCESS = "DONATION_LIST_SUCCESS";
export const DONATION_LIST_FAILURE = "DONATION_LIST_FAILURE";

export const CONTACT_US_LIST_REQUEST = "CONTACT_US_LIST_REQUEST";
export const CONTACT_US_LIST_SUCCESS = "CONTACT_US_LIST_SUCCESS";
export const CONTACT_US_LIST_FAILURE = "CONTACT_US_LIST_FAILURE";

export const SURVEY_LIST_REQUEST = "SURVEY_LIST_REQUEST";
export const SURVEY_LIST_SUCCESS = "SURVEY_LIST_SUCCESS";
export const SURVEY_LIST_FAILURE = "SURVEY_LIST_FAILURE";
export const SURVEY_LIST_RESET = "SURVEY_LIST_RESET";

export const CREATE_HERO_SECTION_REQUEST = "CREATE_HERO_SECTION_REQUEST";
export const CREATE_HERO_SECTION_SUCCESS = "CREATE_HERO_SECTION_SUCCESS";
export const CREATE_HERO_SECTION_FAILURE = "CREATE_HERO_SECTION_FAILURE";

export const GET_HERO_SECTION_LIST_REQUEST = "GET_HERO_SECTION_LIST_REQUEST";
export const GET_HERO_SECTION_LIST_SUCCESS = "GET_HERO_SECTION_LIST_SUCCESS";
export const GET_HERO_SECTION_LIST_FAILURE = "GET_HERO_SECTION_LIST_FAILURE";

export const UPDATE_HERO_SECTION_REQUEST = "UPDATE_HERO_SECTION_REQUEST";
export const UPDATE_HERO_SECTION_SUCCESS = "UPDATE_HERO_SECTION_SUCCESS";
export const UPDATE_HERO_SECTION_FAILURE = "UPDATE_HERO_SECTION_FAILURE";

export const CREATE_MAGAZINE_SURVEY_REQUEST = "CREATE_MAGAZINE_SURVEY_REQUEST";
export const CREATE_MAGAZINE_SURVEY_SUCCESS = "CREATE_MAGAZINE_SURVEY_SUCCESS";
export const CREATE_MAGAZINE_SURVEY_FAILURE = "CREATE_MAGAZINE_SURVEY_FAILURE";

export const GET_MAGAZINE_SURVEY_LIST_REQUEST =
  "GET_MAGAZINE_SURVEY_LIST_REQUEST";
export const GET_MAGAZINE_SURVEY_LIST_SUCCESS =
  "GET_MAGAZINE_SURVEY_LIST_SUCCESS";
export const GET_MAGAZINE_SURVEY_LIST_FAILURE =
  "GET_MAGAZINE_SURVEY_LIST_FAILURE";

export const DELETE_MAGAZINE_SURVEY_REQUEST = "DELETE_MAGAZINE_SURVEY_REQUEST";
export const DELETE_MAGAZINE_SURVEY_SUCCESS = "DELETE_MAGAZINE_SURVEY_SUCCESS";
export const DELETE_MAGAZINE_SURVEY_FAILURE = "DELETE_MAGAZINE_SURVEY_FAILURE";

export const UPDATE_MAGAZINE_SURVEY_REQUEST = "UPDATE_MAGAZINE_SURVEY_REQUEST";
export const UPDATE_MAGAZINE_SURVEY_SUCCESS = "UPDATE_MAGAZINE_SURVEY_SUCCESS";
export const UPDATE_MAGAZINE_SURVEY_FAILURE = "UPDATE_MAGAZINE_SURVEY_FAILURE";

export const getMagazineList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: MAGAZINE_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/public/megazine/get/pdf/v1`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: MAGAZINE_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAGAZINE_LIST_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const createMagazine = (magazine) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_MAGAZINE_REQUEST });
      if (magazine && magazine.file) {
        const imgRes = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/public/megazine/pdf/upload`,
          {
            sFileName: magazine.file.name,
            sContentType: magazine.file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
          }
        );
        const surl = imgRes.data.data.sUrl;
        const sImage = imgRes.data.data.sPath;
        const putRes = await axios.put(surl, magazine.file, {
          headers: { "Content-Type": magazine.file.type },
        });
        const url = `${process.env.REACT_APP_BASE_URL}/create/megazine/pdf/v1`;
        const res = await axios.post(
          url,
          {
            pdfName: magazine.pdfName,
            pdfUrl: sImage,
          },
          {
            headers: {
              Authorization: localStorage.getItem("Authorization"),
            },
          }
        );
        dispatch({ type: CREATE_MAGAZINE_SUCCESS, payload: res.data });
        toast.success("Magazine created successfully");
      } else {
        toast("Please select magazine file");
        return;
      }
    } catch (error) {
      console.error("Magazine creation error:", error);
      dispatch({ type: CREATE_MAGAZINE_FAILURE, payload: error.message });
      toast.error(
        error?.response?.data?.message || "Failed to create magazine"
      );
    }
  };
};

export const deleteMagazine = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_MAGAZINE_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/delete/megazine/pdf/${id}/v1`;
      const res = await axios.put(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: DELETE_MAGAZINE_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_MAGAZINE_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getDonationList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DONATION_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/fetch/megazine/paypal/payments/v1`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: DONATION_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DONATION_LIST_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getContactUsList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CONTACT_US_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/contact/us/list/v1`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: CONTACT_US_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CONTACT_US_LIST_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getSurveyList = (searchInput) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SURVEY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/megazine/user/search/bar/result/v1?searchInput=${searchInput}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: SURVEY_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: SURVEY_LIST_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    } finally {
      dispatch({ type: SURVEY_LIST_REQUEST });
    }
  };
};

export const createHeroSection = (heroSection) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_HERO_SECTION_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/admin/create/hero/section/v1`;
      const res = await axios.post(url, heroSection, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: CREATE_HERO_SECTION_SUCCESS, payload: res.data });
      toast.success("Hero section created successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_HERO_SECTION_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getHeroSectionList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HERO_SECTION_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/get/hero/section/list/v1`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: GET_HERO_SECTION_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_HERO_SECTION_LIST_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const updateHeroSection = (heroSectionId, heroSection) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_HERO_SECTION_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/admin/update/hero/section/${heroSectionId}/v1`;
      const res = await axios.put(url, heroSection, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: UPDATE_HERO_SECTION_SUCCESS, payload: res.data });
      toast.success("Hero section updated successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_HERO_SECTION_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const createMagazineSurvey = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_MAGAZINE_SURVEY_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/admin/create/magazine/survey/v1`;
      const res = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: CREATE_MAGAZINE_SURVEY_SUCCESS, payload: res.data });
      toast.success("Magazine survey created successfully");
    } catch (error) {
      console.error(error);
      dispatch({
        type: CREATE_MAGAZINE_SURVEY_FAILURE,
        payload: error.message,
      });
      toast(error?.response?.data?.message);
    }
  };
};

export const getMagazineSurveyList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MAGAZINE_SURVEY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/get/all/magazine/survey/list/v1`;
      const res = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: GET_MAGAZINE_SURVEY_LIST_SUCCESS, payload: res.data });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MAGAZINE_SURVEY_LIST_FAILURE,
        payload: error.message,
      });
      toast(error?.response?.data?.message);
    }
  };
};

export const deleteMagazineSurvey = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_MAGAZINE_SURVEY_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/admin/delete/magazine/survey/${id}/v1`;
      const res = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: DELETE_MAGAZINE_SURVEY_SUCCESS, payload: res.data });
      toast.success("Magazine survey deleted successfully");
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_MAGAZINE_SURVEY_FAILURE,
        payload: error.message,
      });
      toast(error?.response?.data?.message);
    }
  };
};

export const updateMagazineSurvey = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MAGAZINE_SURVEY_REQUEST });
      const url = `${process.env.REACT_APP_BASE_URL}/admin/update/magazine/survey/${id}/v1`;
      const res = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("Authorization"),
        },
      });
      dispatch({ type: UPDATE_MAGAZINE_SURVEY_SUCCESS, payload: res.data });
      toast.success("Magazine survey updated successfully");
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_MAGAZINE_SURVEY_FAILURE,
        payload: error.message,
      });
      toast(error?.response?.data?.message);
    }
  };
};
