import React from "react";

const Sidebar = ({ activeTab, handleTabClick }) => {
  return (
    <div className="col-md-3 col-lg-2 p-3 bg-light">
      <div className="d-flex flex-column">
        <button
          className={`btn mb-2 ${
            activeTab === "magazine" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => handleTabClick("magazine")}
        >
          Magazine Management
        </button>
        <button
          className={`btn mb-2 ${
            activeTab === "create-magazine-survey"
              ? "btn-primary"
              : "btn-outline-primary"
          }`}
          onClick={() => handleTabClick("create-magazine-survey")}
        >
          Create Magazine Survey
        </button>

        <button
          className={`btn mb-2 ${
            activeTab === "hero-section-management"
              ? "btn-primary"
              : "btn-outline-primary"
          }`}
          onClick={() => handleTabClick("hero-section-management")}
        >
          Hero Section Management
        </button>

        <button
          className={`btn mb-2 ${
            activeTab === "donation" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => handleTabClick("donation")}
        >
          Donation Management
        </button>

        <button
          className={`btn mb-2 ${
            activeTab === "contact" ? "btn-primary" : "btn-outline-primary"
          }`}
          onClick={() => handleTabClick("contact")}
        >
          Contact Us Management
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
