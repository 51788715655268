import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { awsBaseUrl } from "../../helper";
import noProfileUrl from '../../assets/No_profile.png';

const EditUserProfile = ({ editUserProfileModal, editUserProfileToggle, userDetails, userActions }) => {

  const [userImg, setUserImg] = useState("");
  const [username, setUsername] = useState("");
  const [selectedImg, setSelectedImg] = useState("");
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserImg(userDetails?.image);
    setUsername(userDetails?.name);
  }, [userDetails]);

  const handleEditUserProfile = () => {
    dispatch(userActions.updateUserDetail(userDetails?._id, { name: username, image: userImg }, editUserProfileToggle, clearState));
  };

  const handleSelectImage = () => {
    fileRef.current.click();
  };

  const handleSelectedImage = (e) => {
    setSelectedImg(URL.createObjectURL(e.target.files[0]));
    setUserImg({ file: e.target.files[0] });
  };

  const clearState = () => {
    setUserImg(userDetails?.image);
    setUsername(userDetails?.name);
    setSelectedImg("");
  };

  return (
    <React.Fragment>
      <Modal isOpen={editUserProfileModal} toggle={editUserProfileToggle} backdrop={"static"} className="modal-lg" centered>
        <ModalHeader>
          <h4>Add Survey Sponsor</h4>
        </ModalHeader>
        <ModalBody className='p-3'>
          <Row>
            <Col md="4" style={{ position: "relative" }}>
              <img src={selectedImg ? selectedImg : userImg ? `${awsBaseUrl}${userImg}` : noProfileUrl} style={{ width: "100%", height: "200px" }} />
              <i className="fa-solid fa-pen-to-square" style={{ position: "absolute", bottom: "10px", right: "25px", filter: "drop-shadow(3px 3px 10px #000)", cursor: "pointer", color: "#fff" }} onClick={e => handleSelectImage()} ></i>
              <input type="file" style={{ display: "none" }} accept="image/*" ref={fileRef} onChange={e => handleSelectedImage(e)} />
            </Col>
            <Col md="12" className="mt-3">
              <FormGroup>
                <Label>Username</Label>
                <Input type="text" value={username} onChange={e => setUsername(e.target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={e => {
            editUserProfileToggle();
            clearState();
          }}>Cancel</Button>
          <Button color="warning" onClick={e => {
            handleEditUserProfile();
          }}>Add</Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default EditUserProfile;