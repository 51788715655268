import React, { useState } from "react";
import moment from "moment";

const MagazineList = ({
  activeTab,
  handleItemClick,
  handleDeleteMagazine,
  list,
  donationList,
  loading,
  error,
  contactUsList,
  heroSectionList,
  magazineSurveyList,
  handleDeleteMagazineSurvey,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const onDeleteMagazine = async (id) => {
    if (window.confirm("Are you sure you want to delete this magazine?")) {
      await handleDeleteMagazine(id);
    }
  };

  const getCurrentItems = (items) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return items?.slice(indexOfFirstItem, indexOfLastItem);
  };

  // console.log("heroSectionList", heroSectionList);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ totalItems }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="mt-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => paginate(number)}>
                {number}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === pageNumbers.length ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="col-md-9 col-lg-10 p-3">
      {activeTab === "magazine" && (
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title fw-bold">Magazine List</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleItemClick(null, "magazine-create")}
                  >
                    Add New Magazine
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">PDF Name</th>
                        <th scope="col">PDF URL</th>
                        <th scope="col">View Count</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentItems(list)?.map((item) => (
                        <tr key={item._id}>
                          <td>{item.pdfName}</td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${item.pdfUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-decoration-none text-dark"
                              style={{
                                ":hover": {
                                  color: "blue",
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {item.pdfUrl}
                            </a>
                          </td>
                          <td>{item.viewCount}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-info me-2"
                              onClick={() => handleItemClick(item, "magazine")}
                            >
                              View
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => onDeleteMagazine(item._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination totalItems={list?.length || 0} />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "donation" && (
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fw-bold mb-3">Donation List</h5>
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Donor Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Transaction ID</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentItems(
                        [...donationList].sort(
                          (a, b) =>
                            new Date(b.response?.create_time) -
                            new Date(a.response?.create_time)
                        )
                      ).map((item) => (
                        <tr key={item.id}>
                          <td>{item.full_name}</td>
                          <td>
                            {item.amount} {item.currency_code}
                          </td>
                          <td>
                            <span
                              className={`badge bg-${
                                item.status === "COMPLETED"
                                  ? "success"
                                  : "warning"
                              }`}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td>{item.id}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-info"
                              onClick={() => handleItemClick(item, "donation")}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination totalItems={donationList.length} />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "contact" && (
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fw-bold mb-3">Contact Messages</h5>
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCurrentItems(contactUsList).map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-info"
                              onClick={() => handleItemClick(item, "contact")}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination totalItems={contactUsList.length} />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "create-magazine-survey" && (
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title fw-bold">Create Magazine Survey</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleItemClick(null, "create-magazine-survey")
                    }
                  >
                    Create Magazine Survey
                  </button>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Survey</th>
                        <th scope="col">Article Slug</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {magazineSurveyList.map((item) => (
                        <tr key={item._id}>
                          <td>{item.surveyId.title}</td>
                          <td>{item.articleSlug}</td>
                          <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-warning me-2"
                              onClick={() =>
                                handleItemClick(item, "attach-magazine-survey")
                              }
                            >
                              Attach
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() =>
                                handleDeleteMagazineSurvey(item._id)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "hero-section-management" && (
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <h5 className="card-title fw-bold mb-3">
                    Hero Section Management
                  </h5>
                  <table className="table table-striped table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Index</th>
                        <th scope="col">Type</th>
                        <th scope="col">Name / Slug</th>
                        <th scope="col">Updated At</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {heroSectionList.map((item) => (
                        <tr key={item.index}>
                          <td>{item.index}</td>
                          <td>{item.type}</td>
                          {item.type === "survey" ? (
                            <td>
                              {item?.surveyId?.title
                                ? item?.surveyId?.title
                                : "N/A"}
                            </td>
                          ) : (
                            <td>
                              {item?.articleSlug
                                ? item?.articleSlug?.post_title
                                : "N/A"}
                            </td>
                          )}
                          <td>
                            {moment(item?.updatedAt).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-warning px-4"
                              onClick={() =>
                                handleItemClick(item, "hero-section-edit")
                              }
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MagazineList;
