import * as magazineActions from "./megazine.actions";

export const magazineFeatureKey = "magazineInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  donationList: [],
  contactUsList: [],
  isMagazineStatusChange: false,
  isCreateMagazine: false,
  isDonationListChange: false,
  isContactUsListChange: false,
  selectedMagazine: null,
  surveyList: [],
  heroSectionList: [],
  magazineSurveyList: [],
};

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case magazineActions.MAGAZINE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.MAGAZINE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case magazineActions.MAGAZINE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    case magazineActions.CREATE_MAGAZINE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.CREATE_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        isMagazineStatusChange: true,
      };

    case magazineActions.CREATE_MAGAZINE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case magazineActions.DELETE_MAGAZINE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.DELETE_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        isMagazineStatusChange: true,
      };

    case magazineActions.DELETE_MAGAZINE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "MAGAZINE_STATUS_CHANGE":
      return {
        ...state,
        isMagazineStatusChange: false,
      };

    case magazineActions.DONATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        isDonationListChange: false,
      };

    case magazineActions.DONATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        donationList: payload.data,
        isDonationListChange: true,
      };

    case magazineActions.DONATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isDonationListChange: false,
      };

    case magazineActions.CONTACT_US_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        isContactUsListChange: false,
      };

    case magazineActions.CONTACT_US_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        contactUsList: payload.data,
        isContactUsListChange: true,
      };

    case magazineActions.CONTACT_US_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isContactUsListChange: false,
      };

    case magazineActions.SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.SURVEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        surveyList: payload.data,
      };

    case magazineActions.SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.SURVEY_LIST_RESET:
      return {
        ...state,
        surveyList: [],
      };

    case magazineActions.CREATE_HERO_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.CREATE_HERO_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case magazineActions.CREATE_HERO_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.GET_HERO_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.GET_HERO_SECTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        heroSectionList: payload.data,
      };

    case magazineActions.GET_HERO_SECTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.UPDATE_HERO_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.UPDATE_HERO_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case magazineActions.UPDATE_HERO_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.CREATE_MAGAZINE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.CREATE_MAGAZINE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case magazineActions.CREATE_MAGAZINE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.GET_MAGAZINE_SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.GET_MAGAZINE_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        magazineSurveyList: payload.data,
      };

    case magazineActions.GET_MAGAZINE_SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.DELETE_MAGAZINE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.DELETE_MAGAZINE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case magazineActions.DELETE_MAGAZINE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case magazineActions.UPDATE_MAGAZINE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case magazineActions.UPDATE_MAGAZINE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case magazineActions.UPDATE_MAGAZINE_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
