import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as magazineActions from "../../redux/megazine/megazine.actions";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
// import { X } from "lucide-react";
import Sidebar from "./Sidebar";
import MagazineList from "./MagazineList";
import { toast } from "react-toastify";
// Reusable selection input component
const SelectionInput = ({
  label,
  placeholder,
  value,
  onSearch,
  onSelect,
  onClear,
  results,
  isActive,
  onFocus,
  magazineSurveyList,
}) => {
  return (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <div className="relative">
        <div className="flex items-center border rounded">
          <input
            type="text"
            className="form-control border-0"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onSearch(e.target.value)}
            onFocus={onFocus}
          />
          {value && (
            <button
              className="btn btn-link text-secondary p-2"
              onClick={onClear}
              type="button"
              aria-label="Clear selection"
            >
              {/* <X size={18} /> */}
            </button>
          )}
        </div>

        {isActive && results?.length > 0 && (
          <div className="absolute w-full mt-1 bg-white border rounded shadow-lg z-10 max-h-60 overflow-y-auto">
            {!magazineSurveyList
              ? results.map((item) => (
                  <div
                    key={item._id}
                    className="p-3 hover:bg-gray-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => onSelect(item)}
                  >
                    <h6 className="mb-0 text-primary">{item.title}</h6>
                  </div>
                ))
              : results
                  .filter(
                    (item) =>
                      !magazineSurveyList.some(
                        (survey) => survey?.surveyId?._id === item?._id
                      )
                  )
                  .map((filteredItem) => (
                    <div
                      key={filteredItem?._id}
                      className="p-3 hover:bg-gray-100"
                      style={{ cursor: "pointer" }}
                      onClick={() => onSelect(filteredItem)}
                    >
                      <h6 className="mb-0 text-primary">
                        {filteredItem?.title ? filteredItem?.title : "No title"}
                      </h6>
                    </div>
                  ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Magazine = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("magazine");
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [heroType, setHeroType] = useState("");
  const [surveySearch, setSurveySearch] = useState("");
  const [articleSearch, setArticleSearch] = useState("");
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [heroIndex, setHeroIndex] = useState(1);
  const [activeSurveyDropdown, setActiveSurveyDropdown] = useState(false);
  const [activeArticleDropdown, setActiveArticleDropdown] = useState(false);
  const [newMagazine, setNewMagazine] = useState({
    pdfName: "",
    file: null,
  });

  const {
    list = [],
    loading,
    error,
    donationList = [],
    contactUsList = [],
    selectedMagazine,
    isMagazineStatusChange,
    heroSectionList = [],
    surveyList = { surveys: [], articles: [] },
    magazineSurveyList = [],
  } = useSelector((state) => state.magazineInfo || {});

  const toggleModal = () => {
    setSelectedSurvey(null);
    setNewMagazine({ pdfName: "", file: null });
    setSurveySearch("");
    setArticleSearch("");
    setActiveSurveyDropdown(false);
    setSelectedArticle(null);
    setActiveArticleDropdown(false);
    setSelectedItem(null);
    setHeroType("");
    setShowModal(!showModal);

    if (!showModal) {
      // Reset states when opening modal
      setNewMagazine({ pdfName: "", file: null });
      setHeroType("");
      clearSelections();
    }
  };

  useEffect(() => {
    dispatch(magazineActions.getMagazineList());
    dispatch(magazineActions.getDonationList());
    dispatch(magazineActions.getContactUsList());
    dispatch(magazineActions.getHeroSectionList());
    dispatch(magazineActions.getMagazineSurveyList());
  }, [dispatch, isMagazineStatusChange]);

  useEffect(() => {
    // Close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest(".relative")) {
        setActiveSurveyDropdown(false);
        setActiveArticleDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const clearSelections = () => {
    setSelectedSurvey(null);
    setSelectedArticle(null);
    setSurveySearch("");
    setArticleSearch("");
    setActiveSurveyDropdown(false);
    setActiveArticleDropdown(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "magazine") {
      dispatch(magazineActions.getMagazineList());
    }
  };

  const handleItemClick = (item, type) => {
    setSelectedItem(item);
    setModalType(type);
    setShowModal(true);
  };

  // console.log("selectedItem", selectedItem);

  const handleDeleteMagazine = async (id) => {
    if (window.confirm("Are you sure you want to delete this magazine?")) {
      await dispatch(magazineActions.deleteMagazine(id));
      dispatch(magazineActions.getMagazineList());
    }
  };

  const handleCreateMagazine = async (e) => {
    e.preventDefault();
    if (!newMagazine.pdfName || !newMagazine.file) {
      alert("Please fill in all fields");
      return;
    }
    await dispatch(magazineActions.createMagazine(newMagazine));
    dispatch(magazineActions.getMagazineList());
    toggleModal();
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      setNewMagazine({ ...newMagazine, file: files[0] });
    } else {
      setNewMagazine({ ...newMagazine, [name]: value });
    }
  };

  const handleSurveySearch = (value) => {
    setSurveySearch(value);
    if (value) {
      dispatch(magazineActions.getSurveyList(value));
      setActiveSurveyDropdown(true);
    } else {
      setActiveSurveyDropdown(false);
    }
  };

  const handleArticleSearch = (value) => {
    setArticleSearch(value);
    if (value) {
      dispatch(magazineActions.getSurveyList(value));
      setActiveArticleDropdown(true);
    } else {
      setActiveArticleDropdown(false);
    }
  };

  const handleSurveySelect = (survey) => {
    setSelectedSurvey(survey);
    setSurveySearch(survey.title);
    setActiveSurveyDropdown(false);
  };

  const handleArticleSelect = (article) => {
    setSelectedArticle(article);
    setArticleSearch(article.title);
    setActiveArticleDropdown(false);
  };

  const handleHeroSectionSubmit = async () => {
    const data = {
      type: heroType,
      surveyId: selectedSurvey?._id,
      articleSlug: selectedArticle?.slug,
      index: selectedItem?.index,
    };

    const dataForCreateMagazineSurvey = {
      surveyId: selectedSurvey?._id,
      articleSlug: selectedArticle?.slug,
    };

    try {
      const hasSurveyId = magazineSurveyList.some(
        (survey) => survey?.surveyId?._id === selectedSurvey?._id
      );
      if (hasSurveyId || selectedArticle?.slug) {
        await dispatch(
          magazineActions.updateHeroSection(selectedItem._id, data)
        );
        await dispatch(magazineActions.getHeroSectionList());
      } else {
        await dispatch(
          magazineActions.updateHeroSection(selectedItem._id, data)
        );
        await dispatch(
          magazineActions.createMagazineSurvey(dataForCreateMagazineSurvey)
        );
        await dispatch(magazineActions.getMagazineSurveyList());
        await dispatch(magazineActions.getHeroSectionList());
      }
      setHeroType("");
      setSelectedSurvey(null);
      setSurveySearch("");
      setActiveSurveyDropdown(false);
      toggleModal();
    } catch (error) {
      console.error("Error updating hero section:", error);
    }
  };

  const handleCreateMagazineSurvey = async () => {
    const data = {
      surveyId: selectedSurvey?._id,
      articleSlug: selectedArticle?.slug,
    };

    if (!data) {
      toast.error("Please fill details");
      return;
    }

    try {
      await dispatch(magazineActions.createMagazineSurvey(data));
      await dispatch(magazineActions.getMagazineSurveyList());
      setSelectedSurvey(null);
      setSurveySearch("");
      setActiveSurveyDropdown(false);
      toggleModal();
    } catch (error) {
      console.error("Error creating survey:", error);
    }
  };

  const handleAttachMagazineSurvey = async () => {
    const data = {
      surveyId: selectedSurvey?._id,
      articleSlug: selectedArticle?.slug,
    };

    try {
      await dispatch(
        magazineActions.updateMagazineSurvey(selectedItem._id, data)
      ); // Wait for update
      await dispatch(magazineActions.getMagazineSurveyList()); // Fetch updated list after update
      setArticleSearch("");
      setSelectedSurvey(null);
      setSurveySearch("");
      setActiveSurveyDropdown(false);
      toggleModal();
    } catch (error) {
      console.error("Error updating survey:", error);
    }
  };

  const handleDeleteMagazineSurvey = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this magazine survey?")
    ) {
      await dispatch(magazineActions.deleteMagazineSurvey(id));
      dispatch(magazineActions.getMagazineSurveyList());
    }
    dispatch(magazineActions.getMagazineSurveyList());
  };

  const renderHeroSection = () => {
    return (
      <div className="p-4">
        <h5 className="text-primary mb-3">Edit Hero Section</h5>

        <div className="mb-3">
          <label htmlFor="heroType" className="form-label">
            Type
          </label>
          <select
            id="heroType"
            className="form-select"
            value={heroType}
            onChange={(e) => {
              setHeroType(e.target.value);
              clearSelections();
            }}
          >
            <option value="">Please select the type</option>
            <option value="survey">Survey</option>
            <option value="article">Article</option>
          </select>
        </div>

        {heroType === "survey" && (
          <SelectionInput
            label="Please select the survey"
            placeholder="Search Survey"
            value={surveySearch}
            onSearch={handleSurveySearch}
            onSelect={handleSurveySelect}
            onClear={() => {
              setSelectedSurvey(null);
              setSurveySearch("");
              setActiveSurveyDropdown(false);
            }}
            results={surveyList.surveys}
            isActive={activeSurveyDropdown}
            onFocus={() => setActiveSurveyDropdown(true)}
          />
        )}

        {heroType === "article" && (
          <SelectionInput
            label="Please select the article"
            placeholder="Search Article"
            value={articleSearch}
            onSearch={handleArticleSearch}
            onSelect={handleArticleSelect}
            onClear={() => {
              setSelectedArticle(null);
              setArticleSearch("");
              setActiveArticleDropdown(false);
            }}
            results={surveyList.articles}
            isActive={activeArticleDropdown}
            onFocus={() => setActiveArticleDropdown(true)}
          />
        )}

        <div className="mb-3">
          <label htmlFor="heroIndex" className="form-label">
            Index
          </label>
          <input
            type="number"
            id="heroIndex"
            className="form-control"
            value={selectedItem?.index || heroIndex}
            onChange={(e) => setHeroIndex(e.target.value)}
            placeholder="Enter index number"
            min="1"
            max="4"
            disabled
          />
        </div>

        <Button
          color="primary"
          onClick={() => {
            handleHeroSectionSubmit();
          }}
          disabled={
            !(
              (
                (heroType === "survey" && selectedSurvey) ||
                (heroType === "article" && selectedArticle) ||
                !heroIndex ||
                heroIndex > 4
              ) // Disable button if index is greater than 4
            )
          }
        >
          Update Hero Section
        </Button>
      </div>
    );
  };

  const renderCreateMagazineSurveySection = () => {
    return (
      <div className="p-4">
        <h5 className="text-primary mb-3">Create Magazine Survey</h5>

        <SelectionInput
          label="Please select the survey"
          placeholder="Search Survey"
          value={surveySearch || selectedSurvey?.title}
          onSearch={handleSurveySearch}
          onSelect={handleSurveySelect}
          onClear={() => {
            setSurveySearch("");
            setSelectedSurvey(null);
            setActiveSurveyDropdown(false);
          }}
          results={surveyList.surveys}
          isActive={activeSurveyDropdown}
          magazineSurveyList={magazineSurveyList}
          onFocus={() => setActiveSurveyDropdown(true)}
        />
        <Button
          color="primary"
          onClick={() => {
            if (!selectedSurvey?._id) {
              toast.error("Please select the survey");
              return;
            }
            handleCreateMagazineSurvey();
          }}
        >
          Create Magazine Survey
        </Button>
      </div>
    );
  };

  const renderAttachMagazineSurveySection = () => {
    return (
      <div className="p-4">
        <h5 className="text-primary mb-3">Attach Article to Magazine Survey</h5>

        <SelectionInput
          label="Please select the article"
          placeholder="Search Article"
          value={articleSearch}
          onSearch={handleArticleSearch}
          onSelect={handleArticleSelect}
          onClear={() => {
            setSelectedArticle(null);
            setArticleSearch("");
            setActiveArticleDropdown(false);
          }}
          results={surveyList.articles}
          isActive={activeArticleDropdown}
          onFocus={() => setActiveArticleDropdown(true)}
        />

        <Button
          color="primary"
          onClick={() => {
            handleAttachMagazineSurvey();
          }}
        >
          Attach
        </Button>
      </div>
    );
  };

  const renderModalContent = () => {
    const itemToDisplay = selectedMagazine || selectedItem;

    switch (modalType) {
      case "magazine-create":
        return (
          <>
            <ModalHeader className="bg-primary text-white">
              Create New Magazine
            </ModalHeader>
            <ModalBody>
              <div className="p-4">
                <form onSubmit={handleCreateMagazine}>
                  <div className="mb-4">
                    <label className="form-label fw-bold">
                      PDF Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="pdfName"
                      className="form-control form-control-lg"
                      placeholder="Enter PDF name"
                      value={newMagazine.pdfName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label fw-bold">
                      PDF File <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="file"
                        name="file"
                        className="form-control"
                        accept="application/pdf"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <small className="text-muted">
                      Only PDF files are accepted
                    </small>
                  </div>
                  <div className="text-end">
                    <Button
                      color="secondary"
                      className="me-2"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" type="submit">
                      Create Magazine
                    </Button>
                  </div>
                </form>
              </div>
            </ModalBody>
          </>
        );

      case "magazine":
        return (
          <>
            <ModalHeader className="bg-primary text-white">
              Magazine Details
            </ModalHeader>
            <ModalBody>
              <div className="p-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="text-primary mb-4">
                      {itemToDisplay?.pdfName}
                    </h4>
                    <div className="mb-4">
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-eye me-2"></i>
                        <strong>Views: </strong> &nbsp;
                        {itemToDisplay?.viewCount || 0}
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-calendar-event me-2"></i>
                        <strong>Created Date: </strong> &nbsp;
                        {moment(itemToDisplay?.createdAt).format("DD-MM-YYYY")}
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-file-pdf me-2"></i>
                        <strong>PDF URL: </strong> &nbsp;
                        <a
                          href={`${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${itemToDisplay?.pdfUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ms-2"
                        >
                          View PDF
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </>
        );

      case "donation":
        return (
          <>
            <ModalHeader className="bg-primary text-white">
              Donation Details
            </ModalHeader>
            <ModalBody>
              <div className="p-4 border rounded">
                <div className="row row-cols-1 row-cols-md-2 g-4">
                  {/* Donor Information */}
                  <div className="col">
                    <h5 className="text-primary mb-3">Donor Information</h5>
                    <div className="mb-2">
                      <strong>Name:</strong> {itemToDisplay?.full_name}
                    </div>
                    <div className="mb-2">
                      <strong>Email:</strong> {itemToDisplay?.email_address}
                    </div>
                    <div className="mb-2">
                      <strong>Payer ID:</strong> {itemToDisplay?.payer_id}
                    </div>
                    <div className="mb-2">
                      <strong>Amount:</strong>{" "}
                      <span className="text-success">
                        {itemToDisplay?.amount} {itemToDisplay?.currency_code}
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>Payment Date:</strong>{" "}
                      {moment(itemToDisplay?.response?.create_time).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </div>
                    <div className="mb-2">
                      <strong>Update Time:</strong>{" "}
                      {moment(itemToDisplay?.response?.update_time).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </div>
                  </div>

                  {/* Payment Details */}
                  <div className="col">
                    <h5 className="text-primary mb-3">Payment Details</h5>
                    <div className="mb-2">
                      <strong>Transaction ID:</strong>{" "}
                      <span className="text-break">{itemToDisplay?.id}</span>
                    </div>
                    <div className="mb-2">
                      <strong>Merchant ID:</strong>{" "}
                      <span className="text-break">
                        {itemToDisplay?.merchantID}
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>Status:</strong>{" "}
                      <span
                        className={`badge bg-${
                          itemToDisplay?.status === "COMPLETED"
                            ? "success"
                            : "warning"
                        }`}
                      >
                        {itemToDisplay?.status}
                      </span>
                    </div>
                    <div className="mb-2">
                      <strong>Intent:</strong> {itemToDisplay?.response?.intent}
                    </div>
                    <div className="mb-2">
                      <strong>Payee Email:</strong>{" "}
                      <span className="text-break">
                        {
                          itemToDisplay?.response?.purchase_units?.[0]?.payee
                            ?.email_address
                        }
                      </span>
                    </div>
                  </div>
                </div>

                {/* Capture Details */}
                {itemToDisplay?.response?.purchase_units?.[0]?.payments
                  ?.captures && (
                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-primary mt-4 mb-3">
                        Capture Details
                      </h5>
                      {itemToDisplay.response.purchase_units[0].payments.captures.map(
                        (capture, index) => (
                          <div key={index} className="mb-3 p-2 border rounded">
                            <div>
                              <strong>Capture ID:</strong> {capture.id}
                            </div>
                            <div>
                              <strong>Status:</strong> {capture.status}
                            </div>
                            <div>
                              <strong>Amount:</strong> {capture.amount.value}{" "}
                              {capture.amount.currency_code}
                            </div>
                            <div>
                              <strong>Create Time:</strong>{" "}
                              {moment(capture.create_time).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
          </>
        );

      case "contact":
        return (
          <>
            <ModalHeader className="bg-primary text-white">
              Contact Message Details
            </ModalHeader>
            <ModalBody>
              {itemToDisplay ? (
                <div className="p-4 border rounded">
                  <div className="mb-4">
                    <h5 className="text-primary mb-3">Sender Information</h5>
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-person me-2"></i>
                      <strong>Name:&nbsp;</strong>
                      {itemToDisplay.name}
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-envelope me-2"></i>
                      <strong>Email:&nbsp;</strong>
                      {itemToDisplay.email}
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="bi bi-clock me-2"></i>
                      <strong>Date:&nbsp;</strong>
                      {moment(itemToDisplay.date).format("DD-MM-YYYY HH:mm")}
                    </div>
                  </div>
                  <div className="mb-3">
                    <h5 className="text-primary mb-3">Message Content</h5>
                    <div className="p-3 bg-light rounded">
                      <p className="mb-0">{itemToDisplay.description}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center p-4">
                  <h5 className="text-muted">No data found</h5>
                </div>
              )}
            </ModalBody>
          </>
        );

      case "hero-section-edit":
        return renderHeroSection("edit");

      case "create-magazine-survey":
        return renderCreateMagazineSurveySection();

      case "attach-magazine-survey":
        return renderAttachMagazineSurveySection();

      default:
        return null;
    }
  };

  if (error) {
    return <div className="text-center p-5 text-danger">{error}</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar activeTab={activeTab} handleTabClick={handleTabClick} />
        <MagazineList
          activeTab={activeTab}
          handleDeleteMagazineSurvey={handleDeleteMagazineSurvey}
          handleItemClick={handleItemClick}
          handleDeleteMagazine={handleDeleteMagazine}
          list={list}
          donationList={donationList}
          contactUsList={contactUsList}
          loading={loading}
          error={error}
          heroSectionList={heroSectionList}
          magazineSurveyList={magazineSurveyList}
        />
      </div>

      <Modal isOpen={showModal} toggle={toggleModal} size="lg">
        {renderModalContent()}
        {modalType !== "magazine-create" && (
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default Magazine;
