import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { awsBaseUrl } from "../../helper";
import * as userActions from "../../redux/user/user.actions";
import * as surveyActions from "../../redux/survey/survey.actions";
import * as dropdownActions from "../../redux/dropdown/dropdown.actions";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import EditUserProfile from "../usersPage/EditUserProfileModal";
import SendEmailModal from "../usersPage/SendEmailModal";
import FilterSurvey from "./FilterSurvey";
import FilterGroup from "./FilterGroup";
import SendNotificationModal from "../usersPage/SendNotificationModal";
import noProfileUrl from '../../assets/No_profile.png';

const FilterUser = ({ state, setFilterComponentBack }) => {
  const [userIndex, setUserIndex] = useState(0);
  const [commentIndex, setCommentIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [emptyFilterUser, setEmptyFilterUser] = useState(false);
  const [editUserProfileModal, setEditUserProfileModal] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [emailUsers, setEmailUsers] = useState([]);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [filterComponentName, setFilterComponentName] = useState("");
  const [filterComponent, setFilterComponent] = useState(false);
  const [filterComponentState, setFilterComponentState] = useState({});
  const [sendNotificationModal, setSendNotificationModal] = useState(false);
  const [usersFilter, setUsersFilter] = useState({
    minAge: 1,
    maxAge: 100,
    attendedSurvey: null,
    createdSurvey: null,
    createdGroup: null,
    gender: null,
    country: "",
    italy_region: "",
    provincia: "",
    comune: "",
    region: "",
    city: "",
    civil: "",
    education: ""
  });

  const editUserProfileToggle = () => setEditUserProfileModal(!editUserProfileModal);
  const sendEmailToggle = () => setSendEmailModal(!sendEmailModal);
  const sendNotificationToggle = () => setSendNotificationModal(!sendNotificationModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const usersDetails = useSelector(state => state.userInfo.usersDetails);
  const countries = useSelector(state => state.surveyInfo.countries);
  const states = useSelector(state => state.surveyInfo.states);
  const cities = useSelector(state => state.surveyInfo.cities);
  const regions = useSelector(state => state.surveyInfo.regions);
  const provincias = useSelector(state => state.surveyInfo.provincias);
  const comunes = useSelector(state => state.surveyInfo.comunes);
  const civilStateList = useSelector(state => state.dropdownInfo.civilStateType);
  const educationList = useSelector(state => state.dropdownInfo.educationType);
  const isUpdateUserStatus = useSelector(state => state.userInfo.isUpdateUserStatus);
  const prevProps = useRef({ isUpdateUserStatus }).current;

  useEffect(() => {
    if (state !== null) {
      if (state?.emptyFilterUsers) {
        setEmptyFilterUser(true);
      }
      dispatch(userActions.getUserListDetails(search, state.id || "", state.users || [], usersFilter));
    } else {
      setEmptyFilterUser(false);
      dispatch(userActions.getUserListDetails(search, "", [], usersFilter));
    }
    dispatch(surveyActions.getCountryList());
    dispatch(surveyActions.getRegionList());
    dispatch(dropdownActions.civilStateTypeList());
    dispatch(dropdownActions.educationTypeList());
  }, [state]);

  useEffect(() => {
    if (usersDetails[userIndex]?.nation_country?.id) {
      dispatch(surveyActions.getStateList(usersDetails[userIndex]?.nation_country?.id));
    }
    if (usersDetails[userIndex]?.region_state?.id) {
      dispatch(surveyActions.getCityList(usersDetails[userIndex]?.region_state?.id));
    }
    if (usersDetails[userIndex]?.nation_country?.id === "107") {
      if (usersDetails[userIndex]?.region?.id) {
        dispatch(surveyActions.getProvinciaList(usersDetails[userIndex]?.region?.id));
      }
      if (usersDetails[userIndex]?.provincia?.id) {
        dispatch(surveyActions.getComuneList(usersDetails[userIndex]?.provincia?.id));
      }
    }
  }, [usersDetails, userIndex]);

  useEffect(() => {
    if (state !== null) {
      dispatch(userActions.getUserListDetails(search, state.id || "", state.users || [], usersFilter));
    } else {
      dispatch(userActions.getUserListDetails(search, "", [], usersFilter));
    }
  }, [search, state]);

  useEffect(() => {
    if (prevProps.isUpdateUserStatus !== isUpdateUserStatus) {
      if (isUpdateUserStatus) {
        if (state !== null) {
          if (state?.emptyFilterUsers) {
            setEmptyFilterUser(true);
          }
          dispatch(userActions.getUserListDetails(search, state.id || "", state.users || [], usersFilter));
        } else {
          setEmptyFilterUser(false);
          dispatch(userActions.getUserListDetails(search, "", [], usersFilter));
        }
        dispatch(surveyActions.getCountryList());
        dispatch(surveyActions.getRegionList());
        dispatch(dropdownActions.civilStateTypeList());
        dispatch(dropdownActions.educationTypeList());
      }
    }

    return () => {
      prevProps.isUpdateUserStatus = isUpdateUserStatus;
    }
  }, [isUpdateUserStatus]);

  const handlePreviousButton = () => {
    if (userIndex !== 0) {
      setUserIndex(userIndex - 1);
      setCommentIndex(0);
    }
  };

  const handleNextButton = () => {
    if (userIndex !== usersDetails?.length - 1) {
      setUserIndex(userIndex + 1);
      setCommentIndex(0);
    }
  };

  const handlePreviousCommentClick = () => {
    setCommentIndex(commentIndex - 1);
  };

  const handleNextCommentClick = () => {
    setCommentIndex(commentIndex + 1);
  };

  const handleSearchSearchableUsersSurveyList = () => {
    const users = usersDetails?.map(u => u?._id);
    // navigate('/survey', { state: { id: "", users: users, surveys: [], groups: [] } });
    setFilterComponent(true);
    setFilterComponentName("survey");
    setFilterComponentState({
      id: "",
      users: users,
      surveys: [],
      groups: []
    });
  };

  const handleSetNavigateStateSurveyIds = (surveys) => {
    return surveys?.map(s => s?._id);
  };

  const handleChangeUserStatus = () => {
    dispatch(userActions.activeInactiveUser(usersDetails[userIndex]?._id, {}));
  };

  return (
    <React.Fragment>
      {
        filterComponent ?
          <React.Fragment>
            {
              filterComponentName === "survey" &&
              <FilterSurvey state={filterComponentState} setFilterComponentBack={setFilterComponent} />
            }
            {
              filterComponentName === "group" &&
              <FilterGroup state={filterComponentState} setFilterComponentBack={setFilterComponent} />
            }
          </React.Fragment>
          :
          <React.Fragment>
            {
              !emptyFilterUser ?
                <React.Fragment>
                  <Row>
                    <Col style={{ position: "relative", width: "100%", height: "40px" }}>
                      <Button color="primary" style={{ position: "absolute", right: "30px" }} onClick={e => setFilterComponentBack(false)}>Back</Button>
                    </Col>
                  </Row>
                  {
                    usersDetails && usersDetails?.length > 0 ?
                      <React.Fragment>
                        <div className="row mt-4">
                          <div className="col-md-7 col-sm-12">
                            <div className="row">
                              <div className="col-md-6 col-sm-12" style={{ paddingLeft: "32px" }}>
                                <div className="row">
                                  <div className="col-12">
                                    <div style={{ maxHeight: "175px" }} className="">
                                      <img
                                        src={usersDetails[userIndex]?.image ? `${awsBaseUrl}${usersDetails[userIndex].image}` : noProfileUrl}
                                        style={{ objectFit: "cover", maxHeight: "175px" }}
                                        className="w-100"
                                        alt=""
                                        srcset=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={usersDetails[userIndex]?.name}
                                    />
                                  </div>
                                  <div className="col-12 mt-3 d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={usersDetails[userIndex]?.email}
                                    />
                                  </div>
                                  <div className="col-12 mt-3 d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="formGroupExampleInput"
                                      value={usersDetails[userIndex]?.phone}
                                    />
                                  </div>
                                  <div className="col-12 mt-4">
                                    <p className="mb-2">Group referent :</p>
                                    <div className="p-3 group-referent-overflow row">
                                      {
                                        usersDetails[userIndex]?.groups && usersDetails[userIndex]?.groups?.length > 0 ?
                                          <React.Fragment>
                                            {
                                              usersDetails[userIndex]?.groups?.map(g => (
                                                <div className="col-12 mt-2">
                                                  <InputGroup key={g?._id}>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id="formGroupExampleInput"
                                                      disabled
                                                      value={g?.name}
                                                    />
                                                    <InputGroupText>
                                                      <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }}
                                                        // onClick={e => navigate('/group', { state: { id: g?._id, user: "" } })} 
                                                        onClick={e => {
                                                          setFilterComponent(true);
                                                          setFilterComponentName("group");
                                                          setFilterComponentState({
                                                            id: g?._id,
                                                            user: ""
                                                          });
                                                        }}
                                                      ></i>
                                                    </InputGroupText>
                                                  </InputGroup>
                                                </div>
                                              ))
                                            }
                                            <div>
                                              <Input value={usersDetails[userIndex]?.groups?.length} style={{ width: "50px", marginTop: "10px", float: "right" }} />
                                            </div>
                                            <div>
                                              <p style={{ float: "right", marginTop: "10px" }}>
                                                <i className="fa-solid fa-circle-arrow-right fa-3x" style={{ cursor: "pointer" }}
                                                  // onClick={e => navigate('/group', { state: { id: "", user: usersDetails[userIndex]?._id } })}
                                                  onClick={e => {
                                                    setFilterComponent(true);
                                                    setFilterComponentName("group");
                                                    setFilterComponentState({
                                                      id: "",
                                                      user: usersDetails[userIndex]?._id
                                                    });
                                                  }}
                                                ></i>
                                              </p>
                                            </div>
                                          </React.Fragment>
                                          :
                                          <React.Fragment>
                                            <div className="col-12">
                                              <h5 className="text-center text-muted">No Data</h5>
                                            </div>
                                          </React.Fragment>
                                      }
                                    </div>
                                  </div>

                                  <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                    <i className="fa-solid fa-pen-to-square fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => setEditUserProfileModal(true)}></i>
                                    <i className="fa fa-2x fa-paper-plane" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                      setSendNotificationModal(true);
                                      setNotificationUsers([usersDetails[userIndex]?._id]);
                                    }}></i>
                                    <i className="fa fa-2x fa-envelope" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                      setSendEmailModal(true);
                                      setEmailUsers([usersDetails[userIndex]?.email]);
                                    }}></i>
                                    {
                                      usersDetails[userIndex]?.active ?
                                        <i className="fa-solid fa-ban fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleChangeUserStatus()}></i> :
                                        <i className="fa-solid fa-chart-line fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleChangeUserStatus()}></i>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="row">
                                  <div className="col-12">
                                    <p className="mb-2">Profile :</p>
                                    <div className="p-3 profile-overflow">
                                      <div className="col-12 mt-3">
                                        <div className="row">
                                          <div className="col-12 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                                checked={usersDetails[userIndex]?.gender?.name === "male"}
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault1"
                                              >
                                                Male
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault2"
                                                checked={usersDetails[userIndex]?.gender?.name === "female"}
                                              />
                                              <label
                                                className="form-check-label"
                                                for="flexRadioDefault2"
                                              >
                                                Female
                                              </label>
                                            </div>
                                            {
                                              usersDetails[userIndex]?.gender?.isVisible ?
                                                <i className="fa fa-eye" aria-hidden="true"></i> :
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            }
                                          </div>
                                          <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                            <select
                                              className="form-select"
                                              id="validationCustom04"
                                              required
                                            >
                                              <option selected disabled value="">
                                                {moment(usersDetails[userIndex]?.dob?.date).format("MM-DD-YYYY")}
                                              </option>
                                            </select>
                                            {
                                              usersDetails[userIndex]?.dob?.isVisible ?
                                                <i className="fa fa-eye" aria-hidden="true"></i> :
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            }
                                          </div>
                                          <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                            <select
                                              className="form-select"
                                              id="validationCustom04"
                                              value={usersDetails[userIndex]?.nation_country?.id}
                                            >
                                              <option selected disabled value="">
                                                Select Nation
                                              </option>
                                              {
                                                countries && countries?.length > 0 && countries?.map(c => (
                                                  <option key={c?._id} value={c?.id}>{c?.name}</option>
                                                ))
                                              }
                                            </select>
                                            {
                                              usersDetails[userIndex]?.nation_country?.isVisible ?
                                                <i className="fa fa-eye" aria-hidden="true"></i> :
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            }
                                          </div>

                                          {
                                            usersDetails[userIndex]?.nation_country?.id === "107" ?
                                              <React.Fragment>
                                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    placeholder="Select Region"
                                                    value={usersDetails[userIndex]?.region?.id}
                                                  >
                                                    <option selected disabled value="">
                                                      Select Region
                                                    </option>
                                                    {
                                                      regions && regions?.length > 0 && regions?.map(r => (
                                                        <option key={r?._id} value={r?.IdRegione}>{r?.DescrRegione}</option>
                                                      ))
                                                    }
                                                  </select>
                                                  {
                                                    usersDetails[userIndex]?.region?.isVisible ?
                                                      <i className="fa fa-eye" aria-hidden="true"></i> :
                                                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                  }
                                                </div>
                                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    placeholder="Select Provincia"
                                                    value={usersDetails[userIndex]?.provincia?.id}
                                                  >
                                                    <option selected disabled value="">
                                                      Select Provincia
                                                    </option>
                                                    {
                                                      provincias && provincias?.length > 0 && provincias?.map(p => (
                                                        <option key={p?._id} value={p?.IdProvincia}>{p?.DescrProvincia}</option>
                                                      ))
                                                    }
                                                  </select>
                                                  {
                                                    usersDetails[userIndex]?.provincia?.isVisible ?
                                                      <i className="fa fa-eye" aria-hidden="true"></i> :
                                                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                  }
                                                </div>
                                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    placeholder="Select Comune"
                                                    value={usersDetails[userIndex]?.comune?.id}
                                                  >
                                                    <option selected disabled value="">
                                                      Select Comune
                                                    </option>
                                                    {
                                                      comunes && comunes?.length > 0 && comunes?.map(c => (
                                                        <option key={c?._id} value={c?.IdComune}>{c?.DescrComune}</option>
                                                      ))
                                                    }
                                                  </select>
                                                  {
                                                    usersDetails[userIndex]?.comune?.isVisible ?
                                                      <i className="fa fa-eye" aria-hidden="true"></i> :
                                                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                  }
                                                </div>
                                              </React.Fragment>
                                              :
                                              <React.Fragment>
                                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    placeholder="State"
                                                    value={usersDetails[userIndex]?.region_state?.id}
                                                  >
                                                    <option selected disabled value="">
                                                      Select State
                                                    </option>
                                                    {
                                                      states && states?.length > 0 && states?.map(s => (
                                                        <option key={s?._id} value={s?.id}>{s?.name}</option>
                                                      ))
                                                    }
                                                  </select>
                                                  {
                                                    usersDetails[userIndex]?.region_state?.isVisible ?
                                                      <i className="fa fa-eye" aria-hidden="true"></i> :
                                                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                  }
                                                </div>
                                                <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                                  <select
                                                    className="form-select"
                                                    id="validationCustom04"
                                                    placeholder="City"
                                                    value={usersDetails[userIndex]?.city?.id}
                                                  >
                                                    <option selected disabled value="">
                                                      Select City
                                                    </option>
                                                    {
                                                      cities && cities?.length > 0 && cities?.map(c => (
                                                        <option key={c?._id} value={c?.id}>{c?.name}</option>
                                                      ))
                                                    }
                                                  </select>
                                                  {
                                                    usersDetails[userIndex]?.city?.isVisible ?
                                                      <i className="fa fa-eye" aria-hidden="true"></i> :
                                                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                                  }
                                                </div>
                                              </React.Fragment>
                                          }

                                          <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                            <select
                                              className="form-select"
                                              id="validationCustom04"
                                              placeholder="Select Civil State"
                                              value={usersDetails[userIndex]?.civilState?.name}
                                            >
                                              <option selected disabled value="">
                                                Select Civil State
                                              </option>
                                              {
                                                civilStateList && civilStateList?.length > 0 && civilStateList?.map(c => (
                                                  <option key={c?._id} value={c?.type}>{c?.type}</option>
                                                ))
                                              }
                                            </select>
                                            {
                                              usersDetails[userIndex]?.civilState?.isVisible ?
                                                <i className="fa fa-eye" aria-hidden="true"></i> :
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            }
                                          </div>
                                          <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                                            <select
                                              className="form-select"
                                              id="validationCustom04"
                                              placeholder="Select Education"
                                              value={usersDetails[userIndex]?.education?.name}
                                            >
                                              <option selected disabled value="">
                                                Select Education
                                              </option>
                                              {
                                                educationList && educationList?.length > 0 && educationList?.map(e => (
                                                  <option key={e?._id} value={e?.type}>{e?.type}</option>
                                                ))
                                              }
                                            </select>
                                            {
                                              usersDetails[userIndex]?.education?.isVisible ?
                                                <i className="fa fa-eye" aria-hidden="true"></i> :
                                                <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 mt-4">
                                    <p className="mb-2">Comments :</p>
                                    <div className="p-3 comments">
                                      {
                                        usersDetails[userIndex]?.comments?.length > 0 ?
                                          <React.Fragment>
                                            <div className="col-12">
                                              <div className="row">
                                                <div className="col-12 d-flex justify-content-between align-items-center">
                                                  <textarea
                                                    className="form-control"
                                                    value={usersDetails[userIndex]?.comments[commentIndex]?.comment}
                                                    id="floatingTextarea2"
                                                    style={{ height: "100px" }}
                                                  ></textarea>
                                                  <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ marginLeft: "10px", cursor: "pointer" }} aria-hidden="true"></i>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 mt-5">
                                              <div className="row">
                                                <div className="col-6 d-flex align-items-center">
                                                  {
                                                    usersDetails[userIndex]?.comments?.length > 0 && commentIndex !== 0 &&
                                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handlePreviousCommentClick()}></i>
                                                  }
                                                  <input
                                                    type="text"
                                                    className="form-control w-50 mx-3"
                                                    id="formGroupExampleInput"
                                                    value={commentIndex + 1}
                                                  />
                                                  {
                                                    usersDetails[userIndex]?.comments?.length >= 1 && commentIndex !== usersDetails[userIndex]?.comments?.length - 1 &&
                                                    <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNextCommentClick()}></i>
                                                  }
                                                </div>
                                                <div className="col-6 d-flex justify-content-end align-items-center">
                                                  <i className="fa fa-plus" aria-hidden="true"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                          :
                                          <div className="col-12 mt-5">
                                            <h5 className="text-muted text-center">No Comments</h5>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-sm-12">
                            <div className="row m-auto">
                              <div className="col-12 mt-4">
                                <p className="mb-2">Organizer :</p>
                                <div className="p-3 organizer-voter-overflow">
                                  {
                                    usersDetails[userIndex]?.created_surveys && usersDetails[userIndex]?.created_surveys?.length > 0 ?
                                      <React.Fragment>
                                        {
                                          usersDetails[userIndex]?.created_surveys?.map(o => (
                                            <div className="col-12 mt-2">
                                              <InputGroup key={o?._id}>
                                                <input
                                                  type="text"
                                                  className="form-control w-75"
                                                  id="formGroupExampleInput"
                                                  disabled
                                                  value={o?.title}
                                                />
                                                <InputGroupText>
                                                  <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }}
                                                    // onClick={e => navigate('/survey', { state: { id: o?._id, users: [], surveys: [], groups: [] } })}
                                                    onClick={e => {
                                                      setFilterComponent(true);
                                                      setFilterComponentName("survey");
                                                      setFilterComponentState({
                                                        id: o?._id,
                                                        users: [],
                                                        surveys: [],
                                                        groups: []
                                                      });
                                                    }}
                                                  ></i>
                                                </InputGroupText>
                                              </InputGroup>
                                            </div>
                                          ))
                                        }
                                        <div>
                                          <Input value={usersDetails[userIndex]?.created_surveys?.length} style={{ width: "50px", marginTop: "10px", marginBottom: "10px", float: "right" }} />
                                        </div>
                                        <div>
                                          <p style={{ display: "flex", marginTop: "10px", width: "100%", justifyContent: "end" }}>
                                            <i className="fa-solid fa-circle-arrow-right fa-3x" style={{ cursor: "pointer" }}
                                              // onClick={e => navigate('/survey', { state: { id: "", users: [], groups: [], surveys: handleSetNavigateStateSurveyIds(usersDetails[userIndex]?.created_surveys) } })}
                                              onClick={e => {
                                                setFilterComponent(true);
                                                setFilterComponentName("survey");
                                                setFilterComponentState({
                                                  id: "",
                                                  users: [],
                                                  groups: [],
                                                  surveys: handleSetNavigateStateSurveyIds(usersDetails[userIndex]?.created_surveys)
                                                });
                                              }}
                                            ></i>
                                          </p>
                                        </div>
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                        <div className="col-12">
                                          <h5 className="text-center text-muted">No Data</h5>
                                        </div>
                                      </React.Fragment>
                                  }
                                </div>
                              </div>
                              <div className="col-12 mt-4">
                                <p className="mb-2">Voter :</p>
                                <div className="p-3 organizer-voter-overflow">
                                  {
                                    usersDetails[userIndex]?.attended_surveys && usersDetails[userIndex]?.attended_surveys?.length > 0 ?
                                      <React.Fragment>
                                        {
                                          usersDetails[userIndex]?.attended_surveys?.map(v => (
                                            <div className="col-12 mt-2">
                                              <InputGroup key={v?._id}>
                                                <input
                                                  type="text"
                                                  className="form-control w-75"
                                                  id="formGroupExampleInput"
                                                  disabled
                                                  value={v?.title}
                                                />
                                                <InputGroupText>
                                                  <i className="fa-solid fa-circle-arrow-right fa-2x" style={{ cursor: "pointer" }}
                                                    // onClick={e => navigate('/survey', { state: { id: v?._id, users: [], surveys: [], groups: [] } })} 
                                                    onClick={e => {
                                                      setFilterComponent(true);
                                                      setFilterComponentName("survey");
                                                      setFilterComponentState({
                                                        id: v?._id,
                                                        users: [],
                                                        surveys: [],
                                                        groups: []
                                                      });
                                                    }}
                                                  ></i>
                                                </InputGroupText>
                                              </InputGroup>
                                            </div>
                                          ))
                                        }
                                        <div>
                                          <Input value={usersDetails[userIndex]?.attended_surveys?.length} style={{ width: "50px", marginTop: "10px", marginBottom: "10px", float: "right" }} />
                                        </div>
                                        <div>
                                          <p style={{ display: "flex", marginTop: "10px", width: "100%", justifyContent: "end" }}>
                                            <i className="fa-solid fa-circle-arrow-right fa-3x" style={{ cursor: "pointer" }}
                                              // onClick={e => navigate('/survey', { state: { id: "", users: [], groups: [], surveys: handleSetNavigateStateSurveyIds(usersDetails[userIndex]?.attended_surveys) } })} 
                                              onClick={e => {
                                                setFilterComponent(true);
                                                setFilterComponentName("survey");
                                                setFilterComponentState({
                                                  id: "",
                                                  users: [],
                                                  groups: [],
                                                  surveys: handleSetNavigateStateSurveyIds(usersDetails[userIndex]?.attended_surveys)
                                                });
                                              }}
                                            ></i>
                                          </p>
                                        </div>
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                        <div className="col-12">
                                          <h5 className="text-center text-muted">No Data</h5>
                                        </div>
                                      </React.Fragment>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-12"
                            style={{ paddingLeft: "32px", paddingRight: "2rem" }}
                          >
                            <hr className="mt-4 mb-5" />
                          </div>
                          <div className="col-12" style={{ paddingLeft: "32px" }}>
                            <div className="row">
                              <div className="col-3 d-flex align-items-center">
                                <i className="fa fa-chevron-left" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handlePreviousButton()}></i>
                                <input
                                  type="text"
                                  className="form-control w-50 mx-3"
                                  id="formGroupExampleInput"
                                  disabled
                                  value={`${userIndex + 1} of ${usersDetails && usersDetails?.length}`}
                                />
                                <i className="fa fa-chevron-right" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleNextButton()}></i>
                              </div>
                              <div className="col-9 d-flex justify-content-start align-items-center">
                                <i className="fa fa-paper-plane fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                  setSendNotificationModal(true);
                                  setNotificationUsers(usersDetails?.length > 0 && usersDetails?.map(u => u?._id));
                                }}></i>
                                <i className="fa-solid fa-envelope ms-5 fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => {
                                  setSendEmailModal(true);
                                  setEmailUsers(usersDetails?.length > 0 && usersDetails?.map(u => u?.email));
                                }}></i>
                                <i className="fa-solid fa-check-to-slot ms-5 fa-2x" aria-hidden="true" style={{ cursor: "pointer" }} onClick={e => handleSearchSearchableUsersSurveyList()}></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment> :
                      <React.Fragment>
                        <h2 className="text-center mt-5 text-muted">No data found</h2>
                      </React.Fragment>
                  }
                </React.Fragment> :
                <React.Fragment>
                  <h5 className="text-center text-muted">No Attended Users</h5>
                </React.Fragment>
            }

            <EditUserProfile editUserProfileModal={editUserProfileModal} editUserProfileToggle={editUserProfileToggle} userDetails={usersDetails[userIndex]} userActions={userActions} />

            <SendEmailModal sendEmailModal={sendEmailModal} sendEmailToggle={sendEmailToggle} userActions={userActions} emailUsers={emailUsers} setEmailUsers={setEmailUsers} />

            <SendNotificationModal sendNotificationModal={sendNotificationModal} sendNotificationToggle={sendNotificationToggle} userActions={userActions} notificationUsers={notificationUsers} setNotificationUsers={setNotificationUsers} />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default FilterUser;