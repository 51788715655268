/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-indent */

import React, { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import * as userActions from "../redux/user/user.actions";
import Comment from "../views/commentPage/Comment";
import Groups from "../views/groupPage/Groups";
import Payments from "../views/payment/Payments";
import Surveys from "../views/surveyPage/Surveys";
// eslint-disable-next-line
import Megazine from "../views/megazinePage/Megazine";
import Users from "../views/usersPage/Users";

const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Login = lazy(() => import("../components/auth/Login"));
const NotFound = lazy(() => import("../components/NotFound/index"));
const Profile = lazy(() => import("../views/profile/Profile"));
const Dropdown = lazy(() => import("../views/dropdown/Dropdown"));
const ForgotPassword = lazy(() => import("../components/auth/ForgotPassword"));
const Success = lazy(() => import("../components/auth/Success"));
const SurveyResult = lazy(() => import("../views/surveyResult/SurveyResult"));
const Setting = lazy(() => import("../views/setting/Setting"));
const Help = lazy(() => import("../views/help/Help"));
const HtmlDoc = lazy(() => import("../views/optionHtmlDoc/HtmlDoc"));
const HtmlDocMobile = lazy(() =>
  import("../views/optionHtmlDoc/HtmlDocMobile")
);
const Redirect = lazy(() => import("../views/optionHtmlDoc/Redirect"));
const SuccessPayment = lazy(() => import("../components/paymentPages/Success"));
const FailedPayment = lazy(() =>
  import("../components/paymentPages/Failed.js")
);

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("Authorization")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const AllRoutes = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("Authorization");
    if (localStorage.getItem("Authorization")) {
      dispatch(userActions.getLoginUser(navigate));
    }
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login {...props} />} exact />
      <Route
        path="/forgot-password/:resettoken"
        element={<ForgotPassword {...props} />}
        exact
      />
      <Route path="/success" element={<Success {...props} />} exact />
      <Route path="/payment" element={<Payments />} exact />
      <Route
        path="/option/html/doc/:uuid/:token"
        element={<Redirect />}
        exact
      />
      <Route path="/payment/success" element={<SuccessPayment />} exact />
      <Route path="/payment/failed" element={<FailedPayment />} exact />
      <Route path="/comment" element={<Comment />} exact />
      <Route
        path="/survey/result/:id"
        element={<SurveyResult {...props} />}
        exact
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <FullLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="option/html/doc/:uuid"
          element={
            <ProtectedRoute>
              <HtmlDoc />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="option/html/doc/:uuid/mobile"
          element={
            <ProtectedRoute>
              <HtmlDocMobile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path=""
          element={
            <ProtectedRoute>
              <Starter />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="about"
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="alerts"
          element={
            <ProtectedRoute>
              <Alerts />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="badges"
          element={
            <ProtectedRoute>
              <Badges />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="buttons"
          element={
            <ProtectedRoute>
              <Buttons />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="cards"
          element={
            <ProtectedRoute>
              <Cards />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="grid"
          element={
            <ProtectedRoute>
              <Grid />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="table"
          element={
            <ProtectedRoute>
              <Tables />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="forms"
          element={
            <ProtectedRoute>
              <Forms />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="breadcrumbs"
          element={
            <ProtectedRoute>
              <Breadcrumbs />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="dropdown"
          element={
            <ProtectedRoute>
              <Dropdown />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="user"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="group"
          element={
            <ProtectedRoute>
              <Groups />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="survey"
          element={
            <ProtectedRoute>
              <Surveys />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="setting"
          element={
            <ProtectedRoute>
              <Setting />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="help"
          element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          }
          exact
        />
        <Route
          path="magazine"
          element={
            <ProtectedRoute>
              <Megazine />
            </ProtectedRoute>
          }
          exact
        />
      </Route>
      <Route path="*" element={<NotFound />} />

      {/* Reading Training Complete */}

      {/* <Navigate path="/" to="/login" exact />
      <Navigate to="/" /> */}
    </Routes>
  );
};

export default AllRoutes;
